/* Global variables and functions */
var ProjectName = (function($, window, undefined) {
    'use strict';
    var $win = $(window),
        $footer = $('#footer'),
        $header = $('#header'),
        $html = $('html'),
        $main = $('#main'),
        $openMenu = $('.icon-menu'),
        $openContact = $('.icon-chat'),
        $closeMenu = $('.btn-close-menu');

    function _testEqualize() { // Just demo
        var abc = $('.test-equalize').equalize({
            equalize: 'outerHeight',
            children: '.inner',
            reset: true
        });
    }

    function _scrollTo($target) {
        jQuery('#wrapper').css('overflow', 'visible');

        var initialTargetOffset = $target.offset().top;

        /*
        var $scrollBlock1 = $('.scrollblock-item-1');

        if ( $scrollBlock1 && $scrollBlock1.length  ) {
            initialTargetOffset = initialTargetOffset/1.67;
        }
        */

        $('html, body').animate({
            scrollTop: (initialTargetOffset)
        }, 900, 'swing');

        setTimeout(function(){
            jQuery('#wrapper').css('overflow', '');

            var $scrollBlock1 = $('.scrollblock-item-1');

            if ( $scrollBlock1 && $scrollBlock1.length  ) {
                setTimeout(function(){
                    if (initialTargetOffset !== $target.offset().top) {
                        $('html, body').animate({
                            scrollTop: (initialTargetOffset/1.67)
                        }, 500, 'swing');
                    }
                }, 100);

            }
        }, 1100);
    }

    function _detectUrl(e) {
        window.scrollTo(0, 0);

        setTimeout(function() {
            window.scrollTo(0, 0);
        }, 1);

        if (location.hash) {
            _scrollTo($(location.hash));
        }
    }

    function _typing() {
        $(".typed").typed({
            stringsElement: $('.type-more'),
            typeSpeed: 100,
            backDelay: 2000,
            loop: true
        });
    }

    function _menuScroll() {
        $(".wrap-contact-menu, .wrap-navigation").mCustomScrollbar({
            advanced:{ updateOnContentResize: true }
        });
    }

    function _showMenu() {
        $('.icon-menu').on('click', function(event) {
            event.preventDefault();
            $(this).closest('#wrapper').find('.wrap-navigation').slideDown(500);
        });
    }

    function _hideMenu() {
        $('.wrap-navigation').on('click', function(event) {
            //event.preventDefault();
            $(this).slideUp(500);
        });
    }

    function _showContactMenu() {
        $('.icon-chat, .over-mission__readmore').on('click', function(event) {
            event.preventDefault();
            $(this).closest('#wrapper').find('.wrap-contact-menu').slideDown(500);
        });
    }

    function _hideContactMenu() {
        $('.btn-close-menu').on('click', function(event) {
            $(this).closest('.wrap-contact-menu').slideUp(500);
        });
    }

    function _popUp() {
        $('.gallery__popup-link').magnificPopup({
            //type: 'image',
            closeOnContentClick: false,
            removalDelay: 300,
            mainClass: 'mfp-fade',
            gallery:{
                enabled:true
            }
        });

        $('.over-infor__name-link').magnificPopup({
            //type: 'image',
            closeOnContentClick: false,
            removalDelay: 300,
            mainClass: 'mfp-fade',
            gallery:{
                enabled:true
            }
        });
    }

    function _bindEvent() {
        var timeOutResize;

        $win.on('load', function() {
            _testEqualize();
        });
    }

    function _ajaxPostsLoadInit() {

        function ajaxPostsLoad($loadMoreButton) {
            var $postsContainer;
            var ajaxCloseWarning = '<button id="defakto-close-ajax-warning" class="defakto-close-ajax-warning fa fa-close"></button>';
            var post_type = $loadMoreButton.data('post_type');

            if (post_type === 'blog') {
                $postsContainer = $('.blog-list');
            }
            else if (post_type === 'portfolio') {
                $postsContainer = $('.gallery-list');
            }
            else {
                $postsContainer = $('.posts-list');
            }

            if ($postsContainer && $postsContainer.length) {
                var $preloader = $loadMoreButton.children('.ico-repeat');
                var offset = $loadMoreButton.data('post_count');
                var published_posts = $loadMoreButton.data('posts_published');
                var max_posts = $loadMoreButton.data('max_posts');
                var category = $loadMoreButton.data('category');

                var data = {
                        action: 'load_more_posts',
                        post_offset: offset,
                        max_posts : max_posts,
                        category: category,
                        post_type: post_type
                };

                $preloader.addClass('defakto-loading');

                $.post(defakto_js_main.ajax_url, data, function(response) {
                    var $warningBlock = $('.defakto-ajax-warning');
                    response = $.parseJSON(response);

                    $preloader.removeClass('defakto-loading');

                    if ( ! response.error ) {
                        $postsContainer.append(response.posts_html);
                        $loadMoreButton.data('post_count', response.new_offset);

                        if( published_posts > response.new_offset ) {
                            $loadMoreButton.data('post_count', response.new_offset);
                        }
                        else {
                            var ajaxNoMoreInfo = 'Er is niets meer om te laden';

                            if ( $warningBlock && $warningBlock.length ) {
                                $warningBlock.html(ajaxNoMoreInfo + ajaxCloseWarning);
                            }
                            else {
                                $loadMoreButton.parent().append('<p class="defakto-ajax-warning">'+ ajaxNoMoreInfo + ajaxCloseWarning + '</p>');
                            }

                            $loadMoreButton.remove();
                        }


                    }
                    else {
                        var ajaxNoResponse =
                                'Sorry, er is geen respons na ajax gesprek. Alsjeblieft, probeer het later opnieuw.<br>\
                                Als deze fout weer komt, alstublieft, stuur dan een verslag aan de site-eigenaar.';

                        if ( $warningBlock && $warningBlock.length ) {
                            $warningBlock.html(ajaxNoResponse + ajaxCloseWarning);
                        }
                        else {
                            $loadMoreButton.parent().append('<p class="defakto-ajax-warning">'+ ajaxNoResponse + ajaxCloseWarning + '</p>');
                        }
                    }
                }, 'html');
            }
            else {
                var $warningBlock = $('.defakto-ajax-warning');
                var ajaxNoResultBlockWarning =
                        'Sorry, ik kan niet de plaats op deze pagina waar u de berichten te laden te vinden.<br> \
                        Als u deze fout ziet, alstublieft, sturen het rapport aan de site-eigenaar.';

                if ( $warningBlock && $warningBlock.length ) {
                    $warningBlock.html(ajaxNoResultBlockWarning + ajaxCloseWarning);
                }
                else {
                    $loadMoreButton.parent().append('<p class="defakto-ajax-warning">'+ ajaxNoResultBlockWarning + ajaxCloseWarning + '</p>');
                }
            }

            // giving the new DOM time to be formed
            setTimeout(function(){
                $(window).resize();
            }, 1000);
        }

        $(document).on('click', '.blog-button a, .portfolio-load a', function(event){
            event.preventDefault();

            ajaxPostsLoad( $(this) );
            
            setTimeout(function(){
                $(window).resize();
            }, 300);
        });



        $(document).on('click', '.defakto-close-ajax-warning', function(){
            var $loadMoreButton = $(this).parent().children('a');

           $(this).parent().remove();

            setTimeout(function(){
                $(window).resize();
            }, 300);
        });
    }


    function _scrollLinks() {
        $(document).on('click', '#primary-menu .scroll-link a, #main .scroll-link', function(e){
            var $this = $(this);
            var hashPos = $this.attr('href').indexOf('#');
            
            if ( hashPos !== -1 ) {
                var $target = $($this.attr('href').slice(hashPos));
                if ($target && $target.length) {
                    e.preventDefault();
                    _scrollTo( $target );
                }
            }
        });
    }

    function _supportBlendMode() {
        var supportsMixBlendMode = window.getComputedStyle(document.body).mixBlendMode;

        if ( supportsMixBlendMode !== undefined) {
            $('body').addClass('bt-blend-mode');
        }
    }

    return {
        init: function() {
            _detectUrl();
            _typing();
            _showMenu();
            _hideMenu();
            _showContactMenu();
            _hideContactMenu();
            _popUp();
            _bindEvent();
            _ajaxPostsLoadInit();
            _scrollLinks();
            _supportBlendMode();
        }
    };
}(jQuery, window));

function windowWidth() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    var windowWidth = e[ a+'Width' ];
    return windowWidth;
}

function windowHeight() {
    var e = window, a = 'inner';
    if (!('innerHeight' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    var windowHeight = e[ a+'Height' ];
    return windowHeight;
}

jQuery(document).ready(function() {
    function scrollBlocks() {
        var $block1 = jQuery('.scrollblock-item-1');
        var $block2 = jQuery('.scrollblock-item-2');
        var $block3 = jQuery('.scrollblock-item-3');

        if ( ($block1 && $block1.length) && ( ($block2 && $block2.length) || ($block3 && $block3.length) ) ) {
            var configuration = 0;
            var $block1Height, $block2Height, $block3Height;

            if ( $block1 && $block1.length && $block2 && $block2.length ) {
                $block1Height = $block1.outerHeight();
                $block2Height = $block2.outerHeight();

                if ($block2Height > $block1Height) {
                    configuration = 1;
                }
            }
            else if ( $block1 && $block1.length && $block3 && $block3.length ) {
                $block1Height = $block1.outerHeight(true);
                $block3Height = $block3.outerHeight(true);
                configuration = 2;
            }

            if (configuration) {
                var scrollorama = jQuery.scrollorama({
                    blocks:'.scrollblock',
                    enablePin:false
                });


                /*
                var screenWidth = windowWidth();

                if ( screenWidth >= 992) {
                    var screenCoefficient = 1.00;
                }
                else if ( screenWidth >= 601) {
                    var screenCoefficient = 1.00;
                }
                else {
                    var screenCoefficient = 1.00;
                }*/
                var screenCoefficient = 1.00;
                var moveHeight, initialHeight, endHeight;

                if (configuration == 1) {
                    var screenHeight = windowHeight();
                    var sumHeight = $block1Height * screenCoefficient + $block2Height * screenCoefficient;
                    endHeight = $block2Height * screenCoefficient;
                    moveHeight = $block1Height * screenCoefficient;
                    initialHeight = endHeight + moveHeight;

                    if ( $block3 && $block3.length ) {
                        $block3Height = $block3.outerHeight();
                        endHeight += $block3Height * screenCoefficient;
                        sumHeight += $block3Height * screenCoefficient;
                    }


                    if ( (sumHeight - screenHeight) < moveHeight) {
                        moveHeight = sumHeight - screenHeight -20;
                        endHeight = sumHeight - moveHeight;
                        initialHeight = endHeight;
                    }

                    moveHeight *= -1; 

                    $('.scrollblock-wrapper').data('duration', $block1Height);
                    $('.scrollblock-wrapper').data('moveHeight', moveHeight);
                    $('.scrollblock-wrapper').data('initialHeight', initialHeight);
                    $('.scrollblock-wrapper').data('endHeight', endHeight);


                    // scrollorama.animate('.scrollblock-wrapper',{ duration: $block1Height, property:'height', start:initialHeight, end: endHeight});
                    // scrollorama.animate('.scrollblock-item-2',{ duration: $block1Height, property:'top', start:0,end: moveHeight });
                    scrollorama.animate('.scrollblock-item-1',{ duration: $block1Height, property:'margin-bottom', start:0,end: moveHeight });

                    if ( $block3 && $block3.length ) {

                        // scrollorama.animate('.scrollblock-item-3',{ duration: $block1Height, property:'top', start:moveHeight, end: moveHeight });
                    }
                }
                else {
                    moveHeight = $block3Height * screenCoefficient;
                    initialHeight = $block1Height * screenCoefficient;
                    // var endHeight = initialHeight - moveHeight;
                    endHeight = initialHeight;

                    //moveHeight *= -1;

                    $('.scrollblock-wrapper').data('duration', moveHeight);
                    $('.scrollblock-wrapper').data('moveHeight', (moveHeight) );
                    $('.scrollblock-wrapper').data('initialHeight', initialHeight);
                    $('.scrollblock-wrapper').data('endHeight', endHeight);
                    console.log();

                    scrollorama.animate('.scrollblock-wrapper',{duration: moveHeight, property:'height', start:initialHeight, end: endHeight});
                    // scrollorama.animate('.scrollblock-item-1',{duration: moveHeight, property:'top', start:0, end: (moveHeight*-1) });
                    // scrollorama.animate('.scrollblock-item-3',{duration: moveHeight, property:'top', start:(moveHeight*-1), end: (moveHeight*-1) });
                    scrollorama.animate('.scrollblock-item-1',{duration: moveHeight, property:'top', start:0, end: (moveHeight*-1) });
                    scrollorama.animate('.scrollblock-item-3',{duration: moveHeight, property:'bottom', start:moveHeight, end: moveHeight });
                    jQuery('.scrollblock-item-3').css('overflow', 'hidden');
                }
            }
        }
    }

    ProjectName.init();
    scrollBlocks();

    jQuery(document).on('click', '.scroll-link', function(){
        jQuery('#wrapper').css('overflow', 'visible');

        setTimeout(function(){
            jQuery('#wrapper').css('overflow', '');
        }, 1100);
    });
    
    jQuery(document).on('click', '.cta-popup-link', function(event){
        var $contactMenuWrap = jQuery('.wrap-contact-menu');
          
        if ($contactMenuWrap && $contactMenuWrap.length) {
            event.preventDefault();
            $contactMenuWrap.slideDown(500);
        }
    });


    jQuery(window).resize(function() {
        scrollBlocks();
    });
});

jQuery(window).load(function() {
    function portfolioHighlight() {
        var $galleryItems = jQuery('.gallery-list li .gallery__images');

        if ( $galleryItems && $galleryItems.length ) {

            $galleryItems.each(function(index){
                var $galleryItem = jQuery(this);

                setTimeout(function(){
                    $galleryItem.addClass('active');
                }, 4000 + index*6000);

                setTimeout(function(){
                    $galleryItem.removeClass('active');
                }, 10000 + index*6000);
            });
        }
    }

    function menuGetAttention() {
        var $menuIcons = jQuery('.header-icon a');

        if ( $menuIcons && $menuIcons.length ) {
            $menuIcons.each(function(){
                var $menuIcon = jQuery(this);

                setTimeout(function(){
                    $menuIcon.addClass('size-up');

                    setInterval(function(){
                        $menuIcon.addClass('size-up');
                    }, 6000);
                }, 2000);

                setTimeout(function(){
                    $menuIcon.removeClass('size-up');

                    setInterval(function(){
                        $menuIcon.removeClass('size-up');
                    }, 6000);
                }, 2300);
            });
        }
    }

    menuGetAttention();
    portfolioHighlight();
    
    setTimeout(function(){
        $(window).resize();
    }, 300);
});
